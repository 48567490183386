//font variables
$sans: 'PT Sans', sans-serif;
$raleway: 'Raleway', sans-serif;

// font weight
$extra-light: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;

// colors
$green: #00573d;
$yellow: #fdb813;
$yellow-title:#ffe102;
$light-green: #8ed5be;
$orange: #ea7200;
$black : #202020;

//mixin
$tablet-width: 995px;
$desktop-width: 992px;
$breakpoint-alpha: 480px; // adjust to your needs

//bootrap
$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;

$screen-xs-max:              ($screen-sm - 1) !default;
$screen-xs-min:              ($screen-xs + 1) !default;
$screen-sm-max:              ($screen-md - 1) !default;
