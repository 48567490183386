@import 'components/_vars.scss';

@media (max-width: 970px) {
    .contact-map {
        margin-top:40px;
    }

    .navbar-default .navbar-toggle .icon-bar {
        background-color:#fff;
    }

     .navbar-default .navbar-toggle:hover , .navbar-default .navbar-toggle:focus{
        background-color:#fff;

        .icon-bar {
            background-color:$green;
        }
     }
}

@media (max-width: $screen-xs) {
    @import 'media/_smaller-mobile';

    .owl-theme .owl-nav [class*='owl-'] {
        font-size:20px;
    }

    .owl-carousel .owl-nav {
        .owl-prev {left: 0;}
        .owl-next {right: 0;}
    }

    .owl-theme .owl-dots {
        bottom:-10px;
    }

    .owl-theme .owl-dots .owl-dot span {
        width:5px;
        height:5px;
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: #fff;
    }

    article section.highlight .wrap {

        .captions {
            h2 {
                font-size:16px;
            }

            p , .read-more{
                font-size:12px;
            }
        }
    }

    .home-title h2 {
        font-family:$raleway;
        font-size:25px;
        font-weight:$semi-bold;
        color:$green;
        margin:40px 0 20px 0;
        text-transform:lowercase;

        span {
            font-weight:$light;
            text-transform:capitalize;
        }
    }

    .product-links {
        .btn-yellow {
            margin-top:10px;
        }
    }
    #mandiri-links .container {
        padding-top:0;
    }

    .useful-links {
        .btn-grey {
            margin-top:5px;
        }
    }
}

@media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
    @import 'media/_smaller-mobile-landscape';

    .owl-theme .owl-nav [class*='owl-'] {
        font-size:30px;
    }
}

@media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
    @import 'media/_mobile';
}

//container 750px
@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @import 'media/_tablet';

    .owl-theme .owl-nav [class*='owl-'] {
        font-size:40px;
    }
}
@media (min-width: $screen-md) {

}

@media (min-width: $screen-lg) {

}

// menu breakpoint
@media (max-width: 767px) {
    .navbar-default .navbar-form {
        width:100vw;
        float:left;
    }

    header .icon-search {
        top:16px;
    }
}
